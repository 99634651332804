<template>
<div>
     <b-overlay class="fixedHeight" :show="show" rounded="sm" >
 <!-- <b-button class="printCard" variant="primary" @click="printDiv">Print Result Card</b-button> -->
<b-button class="printCard" variant="success" @click="print">Save PDF</b-button>
<div ref="printMe" class="p-2 main">
<div class="row m-3">
    <div class="col-12 topRedBar pr-0">
        <div class="row">
            <div class="col-9 pt-4 pb-4 pl-5 pr-2">
                <div class="row">
                    <div class="col-12 topLogoheaderDiv">
                        <div class="row">
                            <div class="col-4">
                                <img src="@/assets/images/newLogo1.png" style="width: 200px;" alt="logo">
                            </div>
                            <div class="col-4 mt-3 mb-3 leftrightborder text-center">
                                <p>PTE ACADEMIC</p>
                            </div>
                            <div class="col-4  mt-3 mb-3 text-center">
                                <p> SCORE REPORT</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="col-3 pl-5 pr-0">
            <div class="row">
                <div class="col-12 pt-4 pb-4 pr-0">
                    <p class="whiteText pl-3 pt-2 pb-1">
                        SCORE REPORT CODE
                    </p>
                    <div class="scoreReportCode pt-2 pb-2 text-center">
                        <p>
                            {{resultCard.scoreCardCode}}
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
        
    </div>
    <div class="col-12 imageSection pl-5 pt-3">
        <div class="row">
            <div class="col-7 pl-5 rightBorder pb-5">
                <div class="row">
                    <div class="col-3 mt-4 mb-2 text-center pr-0">
                        <img class="candidateImg" v-if="resultCard.personalImage!=''" :src="resultCard.personalImage" alt="candidateImg">
                        <img class="candidateImg" v-else src="@/assets/images/personalImg.png" alt="candidateImg">
                    </div>
                    <div class="col-9 pl-5 mt-5">
                        <p class="candidateInfo pl-1">{{resultCard.name}}</p>
                        <p class="centerInfo mt-1 pl-1"> Test Taker ID: <span>{{resultCard.testTakerId}}</span></p>
                        <p class="centerInfo mt-1 pl-1">Registration ID: <span>{{resultCard.registrationId}}</span></p>
                    </div>
                    
                </div>
            </div>
            <div class="col-4 mt-4">
                <div class="row">
                    <div class="col-6 text-center offset-4 p-0">
                        <p class="overallScoreTitle">OVERALL SCORE</p>
                        <div class="overallScore">{{resultCard.overAllScore}}</div>
                    </div>
                </div>
            </div>
            <div class="col-11 pb-3 border-bottom pl-0 ml-5">
                <p class="headingTitle">COMMUNICATIVE SKILLS</p>
            </div>
            <div class="col-11 pb-3 pl-0 ml-5 mt-4">
                <div class="row mt-2">
                    <div class="offset-1 col-2 text-center">
                        <div class="score redBorder">
                            {{resultCard.communicativeSkille.listening}}
                        </div>
                        <p class="scoreTitle">LISTENING</p>
                    </div>
                    <div class="offset-1 col-2 text-center">
                        <div class="score blueBorder">
                            {{resultCard.communicativeSkille.reading}}
                        </div>
                        <p class="scoreTitle">READING</p>
                    </div>
                    <div class="offset-1 col-2 text-center">
                        <div class="score redBorder">
                            {{resultCard.communicativeSkille.speaking}}
                        </div>
                        <p class="scoreTitle">SPEAKING</p>
                    </div>
                    <div class="offset-1 col-2 text-center">
                        <div class="score blueBorder">
                            {{resultCard.communicativeSkille.writing}}
                        </div>
                        <p class="scoreTitle">WRITING</p>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="row">
                    <div class="col-5 border-bottom pl-0 pb-3">
                        <p class="headingTitle">SKILLS BREAKDOWN</p>
                    </div>
                    <div class="col-6 border-bottom pl-0 offset-1">
                        <p class="headingTitle">TEST CENTER INFORMATION</p>
                    </div>
                </div>
                <div class="row mr-3">
                    <div class="offset-3 border mt-2">
                        <p class="overAllScoreBox">
                            {{resultCard.overAllScore}} OVERALL
                        </p>
                        <div class="verticalLine"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row mt-2">
                            <div class="col-6 text-right">
                                <p class="skillBreakDown">
                                    Listening  <span>
                                        {{resultCard.communicativeSkille.listening}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="redBg" :value="resultCard.communicativeSkille.listening*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-right">
                                <p class="skillBreakDown">
                                    Reading  <span>
                                        {{resultCard.communicativeSkille.reading}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="blueBg" :value="resultCard.communicativeSkille.reading*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Speaking  <span>
                                        {{resultCard.communicativeSkille.speaking}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="redBg" :value="resultCard.communicativeSkille.speaking*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Writing  <span>
                                        {{resultCard.communicativeSkille.writing}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="blueBg" :value="resultCard.communicativeSkille.writing*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6 text-right">
                                <p class="enablingSkills">
                                    Enabling Skills
                                </p>
                            </div>
                            <div class="col-6"></div>
                        </div>
                    
                    <div class="row">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Grammar  <span>
                                        {{resultCard.enablingSkills.grammar}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="redBg" :value="resultCard.enablingSkills.grammar*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Oral Fluency  <span>
                                        {{resultCard.enablingSkills.oralFluency}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="blueBg" :value="resultCard.enablingSkills.oralFluency*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Pronunciation  <span>
                                        {{resultCard.enablingSkills.pronunciation}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="redBg" :value="resultCard.enablingSkills.pronunciation*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Spelling  <span>
                                        {{resultCard.enablingSkills.spelling}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="blueBg" :value="resultCard.enablingSkills.spelling*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Vocabulary  <span>
                                        {{resultCard.enablingSkills.vocabulary}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="redBg" :value="resultCard.enablingSkills.vocabulary*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6  text-right">
                                <p class="skillBreakDown">
                                    Written Discourse  <span>
                                        {{resultCard.enablingSkills.writtenDiscourse}}
                                    </span>
                                </p>
                            </div>
                            <div class="progressBarDiv ">
                                <b-progress :max="max" >
                                    <b-progress-bar class="blueBg" :value="resultCard.enablingSkills.writtenDiscourse*2"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <h5 class="candidateInfo"> Test Date: <span> {{resultCard.testCenterInfo.testDate}}</span></h5>
                        <h5 class="candidateInfo"> Valid Date: <span>{{resultCard.testCenterInfo.validDate}}</span></h5>
                        <h5 class="candidateInfo"> Report Issue Date: <span>{{resultCard.testCenterInfo.reportIssueDate}}</span></h5>
                        <h5 class="candidateInfo"> Test Center Country: <span>{{resultCard.testCenterInfo.testCenterCountry}}</span></h5>
                        <h5 class="candidateInfo"> Test Center ID: <span>{{resultCard.testCenterInfo.testCenterId}}</span></h5>
                        <h5 class="candidateInfo"> Test Center: <span>{{resultCard.testCenterInfo.testCenter}}</span></h5>
                        <div class="row">
                            <div class="col-12 border-bottom pl-0 mt-1 pb-2">
                            <p class="headingTitle">CANDIDATE INFORMTION</p>
                            </div>
                        </div>
                        <h5 class="candidateInfo mt-3"> Date of Birth: <span>{{resultCard.candidateInfo.dob}}</span></h5>
                        <h5 class="candidateInfo "> Country of Citinzenship: <span>{{resultCard.candidateInfo.countryCitizen}}</span></h5>
                        <h5 class="candidateInfo "> Country of Residence: <span>{{resultCard.candidateInfo.countryResident}}</span></h5>
                        <h5 class="candidateInfo "> Gender: <span>{{resultCard.candidateInfo.gender}}</span></h5>
                        <h5 class="candidateInfo "> Email: <span>{{resultCard.candidateInfo.email}}</span></h5>
                        <h5 class="candidateInfo "> First Time Test Taker: <span>{{resultCard.candidateInfo.firstTimeTestTaker}}</span></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row resultCardfooter m-0 mt-4" style="background: rgb(158 30 30);">
            <div class="col-12 text-right">
                <p class="poweredBy">Powered By</p>
            </div>
            <div class="col-3 contactus pt-2">
                <div class="row">
                    <div class="col-4 text-right mr-0 pr-0">
                        <div class="imageDiv">
                            <img src="@/assets/images/assistance.png" alt="">
                        </div>
                    </div>
                    <div class="col-8 text-left ml-0 pl-2">
                        <p>Need Assistance?</p>
                        <p>Call Now</p>
                        <p>+61 435 600 610</p>
                    </div>
                </div>
            </div>
            <div class="col-3 gmail pt-2">
                <div class="row">
                    <div class="col-4 text-right mr-0 pr-0">
                        <div class="imageDiv">
                            <img src="@/assets/images/GmailLogo.png" alt="">
                        </div>
                    </div>
                    <div class="col-8 text-left ml-0 pl-2 pt-2">
                        <p>Contact us</p>
                        <p>info@pteclasses.com</p>
                    </div>
                </div>
            </div>
            <div class="col-3 socialMedia pt-2">
                <div class="socialImageDiv text-center">
                    <p class="followus"> Follow us</p>
                    <a href="https://www.facebook.com/PTEandNAATIclasses"> <img src="@/assets/images/Facebook.png" alt=""></a>
                    <a href="https://twitter.com/PteClasses"><img src="@/assets/images/Twitter.png" alt=""></a>
                    <a href="https://www.instagram.com/pteclasses/"><img src="@/assets/images/Instagram.png" alt=""></a>
                    <a href=""><img src="@/assets/images/Pinterest.png" alt=""></a>
                    <a href="https://www.youtube.com/channel/UCJrv5Ati1JbYzek6kB6cpTg"><img src="@/assets/images/Youtube-icon.png" alt=""></a>
                </div>
            </div>
            <div class="col-3 text-center whiteBackground">
                <img src="@/assets/images/thinkHigherlogo.png" alt="logo">
            </div>
        </div>
</div>
</div>
<template #overlay>
        <div class="text-center">
          <b-icon icon="cloud-upload-fil" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" style="color:#9e1e1e;font-size:2rem;">Generating PDF...</p>
        </div>
      </template>
    </b-overlay>
</div>
</template>
<script>
import { jsPDF } from "jspdf";
export default {
    props:['userResultData'],
  data () {
    return {
    output:null,
    max:180,
    show:false,
      resultCard: {
        name: 'Aneel Khowaja',
        testTakerId: 'PTE001363663',
        registrationId: '386594649',
        scoreCardCode:'021356482312',
        overAllScore: 70,
        personalImage:'',
        communicativeSkille: {
          listening: 75,
          reading: 80,
          speaking: 87,
          writing: 78
        },
        testCenterInfo: {
          testDate: '29 Dec 2020',
          testCenterCountry: 'Australia',
          testCenterId: '85255',
          validDate: '29 Dec 2022',
          reportIssueDate: '29 Dec 2020',
          testCenter: 'Pearson Temporary Test Center-Melbourne VIC'
        },
        enablingSkills:{
            grammar:73,
            oralFluency:90,
            pronunciation:59,
            spelling:63,
            vocabulary:85,
            writtenDiscourse:63
        },
        candidateInfo: {
          dob: '10 April 1997',
          countryResident: 'Australia',
          email: 'aneelkhowaja@gmail.com',
          countryCitizen: 'Pakistan',
          gender: 'Male',
          firstTimeTestTaker: 'Yes'
        }
      }
    }
  },
  methods: {
    async print() {
        this.show=true
        const user=this.$ls.get('user')
        // Can be used if required only need to work on quality
        const filename  = 'Mock-Result-'+user.user_id+'-'+user.first_name+'.pdf';
            var vm=this
            this.printByRef().then(()=>{
                let pdf = new jsPDF('p', 'mm', 'a4',true);
                //alert(filename)
                //var convert=await canvas.toDataURL('image/png')
                pdf.addImage(vm.output, 'PNG', 0, 0, 211, 298);
                pdf.save(filename);
                vm.show=false
            });
    },
    async printByRef(){
        const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.
      const options = {
        type: 'dataURL',
        scale: 4
      }
      this.output = await this.$html2canvas(el, options);
      //this.printDiv()
    },
    printDiv () {
        this.printByRef().then(()=>{
            var a = window.open('', '', 'height=1200, width=1200')
            a.document.write('<html>')
            a.document.write('<body width:95vw;height:100vh"><h2>Save & Print from PTE Classes </h2></br> <img style="display: block; width: 100%;object-fit: contain;" src="'+this.output+'" onload="print()"/>')
            //a.document.write(divContents)
            a.document.write('</body></html>')
            a.document.focus()
            a.document.print()
            a.document.close()
            a.close()
        })
    }
  },
  beforeMount(){
      if(this.userResultData!=null){
        this.resultCard.testTakerId=this.userResultData.testTakerId,
        this.resultCard.registrationId=this.userResultData.registrationId,
        this.resultCard.scoreCardCode=this.userResultData.scoreCardCode,
          this.resultCard.name=this.userResultData.name
          this.resultCard.overAllScore=this.userResultData.overAllScore
          this.resultCard.communicativeSkille=this.userResultData.communicativeSkille
          this.resultCard.candidateInfo=this.userResultData.candidateInfo
          this.resultCard.testCenterInfo.testDate=this.userResultData.testDate
          this.resultCard.testCenterInfo.validDate=this.userResultData.validDate
          this.resultCard.testCenterInfo.reportIssueDate=this.userResultData.reportIssueDate
          this.resultCard.personalImage=this.userResultData.personalImage
          this.resultCard.enablingSkills=this.userResultData.enablingSkills
          //console.log(this.resultCard.personalImage)
      }
  },
  created()
  {
      this.$nextTick(()=>{
            var element=document.getElementsByClassName('verticalLine')[0]
          element.style.marginLeft = this.resultCard.overAllScore*2+"px";
          })
  }
}
</script>
<style scoped>
.printCard {
    position: absolute;
    left: 50%;
    top: 20px;
    z-index: 5;
    /* padding: 6px;
    background: #003ec6!important;
    color: #fff!important;
    border-radius: 5px;
    border-color: #003ec6; */
}
.topRedBar
{
    /* max-height: 120px; */
    background-color: #9e1e1e;
}
.topRedBar .topLogoheaderDiv
{
    height: 100%;
    background-color: #fff;
    display: block;
    border-radius: 50px;
    padding: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.topLogoheaderDiv img {
    display: inline-block;
    position: relative;
    width: 75%;
}
.leftrightborder{
    border: 2px solid #000;
    border-top: 0px;
    border-bottom: 0px;
}
.topLogoheaderDiv p, .scoreReportCode p{
    font-size: 1rem;
    font-weight: 600;
    color: #000;
}
.topLogoheaderDiv p{
    line-height: 40px;
    font-size: 1.25rem;
}
.scoreReportCode p{
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.whiteText
{
    font-size: 1rem;
    font-weight: 500;
    color: #fff!important;
    display: block;
}
.scoreReportCode{
    background-color: #fff;
    border-radius: 50px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    display: block;
}
.imageSection
{
    background-color: #fff;
}
.candidateImg {
    -o-object-fit: cover;
    object-fit: cover;
    width: 140px;
    max-height: 150px;
    border-radius: 10px;
}
.rightBorder
{
    border-right: 1px solid #ccc;
    margin-bottom: -20px;
}
.candidateInfo{
    font-size: 27px;
    padding-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
}
.centerInfo
{
    font-size: 17px;
    font-weight: 600;
    word-spacing: 4px;
}
.centerInfo span{
    font-weight: 400;
    letter-spacing: 1px;
}
.headingTitle
{
    font-size: 25px;
    font-weight: 600;
    color: #323232!important;
}
.overallScoreTitle
{
    font-size: 18px;
    color: #fff!important;
    word-spacing: 4px;
    background: #0b54b8;
    border-top-left-radius: 15px;
    padding: 2px;
    border-top-right-radius: 15px;
    font-weight: 100;
    width: 180px;
}
.overallScore
{
    font-size: 6.5rem;
    font-weight: 700;
    background: #0e2967;
    /* padding-top: 15px; */
    /* display: block; */
    line-height: 7rem;
    /* padding-bottom: 2rem; */
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    color: #fff!important;
    display: block;
    width: 180px;
    /* height: 144px; */
    /* vertical-align: middle; */
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
}
.score
{
    font-size: 36px;
    font-weight: 600;
    display: block;
    /* padding: 0rem; */
    border-radius: 50%;
    width: 110px;
    height: 110px;
    line-height: 95px;
    /* text-align: center; */
    /* margin: auto; */
    /* line-height: 110px; */
    padding: 0px;
    color: #000!important;
}
.score.redBorder{
     border: .4rem solid #9e1e1e;
}
.score.blueBorder{
     border: .4rem solid #0e2967
}
.scoreTitle{
    padding-top: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}
h5.candidateInfo {
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 10px;
    color: #323232!important;
}

h5.candidateInfo span {
    font-weight: 300;
}
.overAllScoreBox
{
    font-size: 1.2rem;
    font-weight: 800;
    padding: 2px 20px;
    width: 180px;
    text-align: center;
}
.verticalLine
{
    width: 3px;
    height: 370px;
    position: absolute;
    background: #5c5c5c;
    margin-top: 5px;
    z-index: 9;
}
.skillBreakDown
{
    font-weight: 600;
    padding-top: 5px;
    color: #323232!important;
}
.skillBreakDown span{
    font-weight: 500;
    display: inline-block;
    padding-left: 5px;
}
.progressBarDiv
{
    width: 180px;
    text-align: center;
    padding: 0px;
    padding-top: 5px;
    margin-left: -3px;
}
.progressBarDiv .progress
{
    padding: 0px;
    margin: 0px;
    height: 1.35rem;
    border-radius: 0px;
    background-color: #fff;
}
.redBg
{
    background-color: #9e1e1e!important;
}
.blueBg
{
    background-color: #0e2967!important;
}
.enablingSkills
{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
}
/* Footer Css */

.borderDiv {
    border-right: 3px solid #b6b6b6;
    margin-top: -20px;
    padding-top: 20px;
}

.resultCardfooter {
    background-color: #ce0404;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.poweredBy {
    color: #fff!important;
    font-weight: 600;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.whiteBackground {
    background: #fff;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 35px;
}

.whiteBackground img {
    display: inline-block;
    position: relative;
    width: 80%;
}

.contactus p,
.gmail p,
.followus {
    color: #fff!important;
    font-size: 11px;
    display: block;
    padding: 0px;
    line-height: 12px;
    font-weight: 600;
}

.gmail p {
    line-height: 13px;
}

.contactus .imageDiv,
.gmail .imageDiv {
    width: 40px;
    display: inline-block;
    background: #fff;
    border-radius: 50px;
    height: 40px;
    text-align: center;
    padding-top: 3px;
    border: 1px solid #003dc7;
}

.gmail .imageDiv {
    padding-top: 5px;
}

.contactus img,
.imageDiv img,
.socialImageDiv img {
    display: inline-block;
    width: 25px;
    object-fit: contain;
}

.socialImageDiv img {
    width: 30px;
}
@media only screen and (max-width: 1025px) {
  .modal.show .modal-dialog {
        min-width: 98%!important;
        width: 98%!important;
        max-width: 98%!important;
    }
}
</style>